* {
    font-family: 'Montserrat', sans-serif !important;
}

*.icon {
    font-family: Icons !important;
}

body {
    margin: 0;
    padding: 0;
}

.full-background {
    /*background-image: ;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background: -moz-linear-gradient(rgba(25, 36, 44, 0.34) 10%, rgba(25, 36, 44, 0.25), rgba(25, 36, 44, 0.17), rgba(0, 0, 0, 0.11)), url('./images/planning-board.jpg');
    background: -webkit-linear-gradient(rgba(25, 36, 44, 0.34) 10%, rgba(25, 36, 44, 0.25), rgba(25, 36, 44, 0.17), rgba(0, 0, 0, 0.11)), url('./images/planning-board.jpg');
    background: linear-gradient(rgba(25, 36, 44, 0.34) 10%, rgba(25, 36, 44, 0.25), rgba(25, 36, 44, 0.17), rgba(0, 0, 0, 0.11)), url('./images/planning-board.jpg');
}

.input-light-font > div > input, select, textarea {
    color: #597e93 !important;
}

.dp-container {
    /*background-color: rgba(40, 40, 40, 0.9);*/
    background-color: transparent;
}

.dp-container > input {
    /*color: #597e93 !important;*/
    background-color: transparent !important;
    border-color: transparent !important;
}

.dp-overlay {
    z-index: 40;
    position:absolute;
    background-color: rgba(255, 255, 255, 0.95);
}

.small-margin {
    margin: 2px !important;
}

.margin-10 {
    margin-bottom: 10px !important;
}

.sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 65px !important;
}

.wider-container {
    margin: 0 7% !important;
}

.ui.page.modals.transition.visible {
    /*this is a semantic ui temp. bug fix*/
    display: flex !important;
}
